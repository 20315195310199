<template>
  <div>
    <page-title :heading="$t('erp.lang_extra') " show-previous-button :subheading="subheader"
                :icon=icon></page-title>
    <div class="app-main__inner">
      <add-update-garnish v-model="subheader"></add-update-garnish>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import AddUpdateGarnish from "../../../components/erp/baseData/AddUpdateGarnish";

export default {
  components: {
    PageTitle,
    AddUpdateGarnish
  },

  data: () => ({
    subheader: '',
    icon: 'pe-7s-plugin icon-gradient bg-tempting-azure',
  })
}
</script>
